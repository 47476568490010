/*! global header & footer **/

.q-gl-header {
	font-family: "Gotham", sans-serif;
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	z-index: 2000;
	width: 100%;
	min-width: 360px;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	box-shadow: 0 20px 16px -18px rgba(0, 0, 0, .15);
}

@media (min-width: 640px) {
	.q-gl-header {
		box-shadow: 0 22px 24px -25px rgba(0, 0, 0, .15);
	}
}

.q-gl-header-container {
	position: relative;
	display: flex;
	height: 47px;
	flex-direction: row;
	align-items: center;
}

@media (min-width: 640px) {
	.q-gl-header-container {
		height: 60px;
	}
}

@media (min-width: 1024px) {
	.q-gl-header-container {
		height: 80px;
	}
}

.q-gl-header-mobile-btn {
	margin-right: 0.75rem;
	display: flex;
	align-items: center;
}

@media (min-width: 768px) {
	.q-gl-header-mobile-btn {
		margin-right: 1rem;
	}
}

@media (min-width: 1024px) {
	.q-gl-header-mobile-btn {
		margin-right: 0px;
		display: none;
	}
}

.q-gl-header-logo {
	width: 6rem;
}

@media (min-width: 1024px) {
	.q-gl-header-logo {
		width: auto;
		min-width: 104px;
	}
}

.q-gl-header-mm-container {
	margin-left: 1.25rem;
	display: none;
	height: 100%;
}

@media (min-width: 1024px) {
	.q-gl-header-mm-container {
		margin-left: 2rem;
		display: block;
	}
}

.q-gl-header-sm-container {
	margin-left: auto;
	height: 100%;
}

.q-gl-mm-top {
	position: relative;
	margin: 0px;
	display: flex;
	height: 100%;
	list-style-type: none;
	align-items: center;
	gap: 0px;
	padding: 0px;
	font-size: 16px;
	line-height: 1.75;
}

.menu-item {
	height: 100%;
}

.menu-item-a {
	display: flex;
	height: 100%;
	align-items: center;
	padding: 0.5rem;
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
}

@media (min-width: 1024px) {
	.menu-item-a {
		padding: 0.75rem;
	}
}

.menu-item-txt {
	letter-spacing: -0.025em;
	color: #727272;
	font-weight: 500
}

.q-nav-items {
	margin: 0px;
	display: flex;
	list-style-type: none;
	flex-direction: column;
	gap: 0.75rem;
	padding: 0px;
}

.q-nav-items li {
	margin: 0px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.q-nav-items li>a {
	display: block;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-size: 16px;
	line-height: 1.5;
	--tw-text-opacity: 1;
	color: rgb(51 59 70 / var(--tw-text-opacity));
	text-decoration: none;
}

.q-megamenu-dropdown {
	transform: translateY(20px);
	transition: transform 0.2s ease, visibility 0.2s ease, opacity 0.2s ease;
	visibility: hidden;
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: 50;
	width: 100%;
	opacity: 0
}

.dropdown-active .q-megamenu-dropdown {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}

.megamenu-content {
	transition: 0.25s ease;
	min-width: 200px;
	background-position: right bottom;
	position: absolute;
	opacity: 0;
}

.megamenu-content.platform {
	width: 1320px;
	max-width: 100%;
}

.megamenu-content.solutions {
	width: 686px;
}

.megamenu-content.customers {
	width: 367px;
}

.megamenu-content.resources {
	width: 686px;
}

.megamenu-content.support {
	width: 367px;
}

.megamenu-content.more {
	width: 686px;
}

.megamenu-content.community {
	width: 280px;
}

.megamenu-content.login {
	width: 460px;
}

.megamenu-content.contact {
	width: 360px;
}

.megamenu-content.trial {
	width: 360px;
}

.megamenu-item {
	visibility: hidden;
}

.megamenu-item.active {
	visibility: visible;
}

.megamenu-item.\!active {
	visibility: visible !important;
}

.megamenu-item.active .megamenu-content {
	opacity: 1;
}

.megamenu-item.\!active .megamenu-content {
	opacity: 1 !important;
}

.megamenu-bg {
	overflow: hidden;
	z-index: -1;
	background-position: right bottom;
	position: absolute;
	max-width: 100%;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	--tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
	filter: var(--tw-drop-shadow)
}

.dropdown-active-ani .megamenu-bg,
.dropdown-active-ani .megamenu-wrap {
	transition: all 0.25s ease;
	will-change: width, height, left, right;
}

.q-megamenu-platform-item {
	letter-spacing: -0.025em;
	margin-bottom: 0.25rem;
	display: block;
	border-color: transparent;
	padding-left: 1rem;
	font-size: 16px;
	line-height: 1.75;
	line-height: 1.5rem;
	--tw-text-opacity: 1;
	color: rgb(51 59 70 / var(--tw-text-opacity));
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 200ms;
	transition-timing-function: linear;
	text-decoration: none;
	font-family: "Gotham", sans-serif;
}

@media (min-width: 1024px) {
	.q-megamenu-platform-item {
		border-radius: 0.25rem;
		border-left-width: 6px;
		padding: 0.75rem;
	}

	.q-megamenu-platform-item:hover {
		--tw-bg-opacity: 1;
		background-color: rgb(243 245 248 / var(--tw-bg-opacity));
	}
}

.q-megamenu-platform-item.active {
	--tw-bg-opacity: 1;
	background-color: rgb(243 245 248 / var(--tw-bg-opacity));
}

.q-megamenu-platform-item.\!active {
	--tw-bg-opacity: 1;
	background-color: rgb(243 245 248 / var(--tw-bg-opacity));
}

.q-megamenu-platform-item.active {
	--tw-border-opacity: 1;
	border-color: rgb(120 162 203 / var(--tw-border-opacity));
}

.q-megamenu-platform-item.\!active {
	--tw-border-opacity: 1;
	border-color: rgb(120 162 203 / var(--tw-border-opacity));
}

.megamenu-bg.showFiletredItem {
	background-image: url(https://ik.imagekit.io/qualys/image/platform-menu-shield.png);
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: contain;
}

.showFiletredItem~.megamenu-wrap .platform.megamenu-content .q-nav-items-cols {
	display: grid;
	grid-template-columns: 50% 50%;
	max-width: unset;
}

.megamenu-wrap {
	position: absolute;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
}

.megamenu-content-wrap {
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.megamenu-content-grid {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.megamenu-content-grid> :not([hidden])~ :not([hidden]) {
	--tw-divide-x-reverse: 0;
	border-right-width: calc(1px * var(--tw-divide-x-reverse));
	border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.megamenu-content-col {
	grid-column: span 1 / span 1;
	padding-right: 1rem;
}

.megamenu-content-col2 {
	grid-column: span 1 / span 1;
	padding-left: 1rem;
}

.megamenu-content-col3 {
	grid-column: span 1 / span 1;
	padding-left: 1rem;
	padding-right: 1rem;
}

.megamenu-content-header {
	margin-bottom: 0.75rem;
	margin-top: 0;
	border-bottom-width: 1px;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-size: 16px;
	line-height: 1.75;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(33 37 44 / var(--tw-text-opacity));
	letter-spacing: -0.025em;
}

.megamenu-platform-text {
	margin-bottom: 1.5rem;
}

.megamenu-platform-wrap {
	padding: 0px !important;
}

.megamenu-platform-box {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.megamenu-platform-header {
	font-size: 16px;
	line-height: 1.75;
	font-weight: 500;
	text-decoration: none;
	--tw-text-opacity: 1;
	color: rgb(84 94 108 / var(--tw-text-opacity));
	letter-spacing: -0.025em;
}

.megamenu-platform-intro {
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.025em;
	margin: 0;
	--tw-text-opacity: 1;
	color: rgb(84 94 108 / var(--tw-text-opacity));
	letter-spacing: -0.025em;
}

.megamenu-apps-container {
	grid-column: span 3 / span 3;
	padding-left: 1rem;
}

.q-pb-5 {
	padding-bottom: 1.25rem;
}

.q-pt-4 {
	padding-top: 1rem;
}

.megamenu-apps-header {
	letter-spacing: -0.025em;
	margin-bottom: 0.75rem;
	margin-top: 0;
	border-radius: 0.25rem;
	border-left-width: 6px;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	padding-left: 0.75rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-transform: uppercase;
}

.megamenu-apps-header--av {
	--tw-border-opacity: 1;
	border-color: rgb(104 109 252 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(104 109 252 / var(--tw-text-opacity));
}

.megamenu-apps-header--vm {
	--tw-border-opacity: 1;
	border-color: rgb(240 65 65 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(240 65 65 / var(--tw-text-opacity));
}

.megamenu-apps-header--rr {
	--tw-border-opacity: 1;
	border-color: rgb(255 123 27 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(255 123 27 / var(--tw-text-opacity));
}

.megamenu-apps-header--td {
	--tw-border-opacity: 1;
	border-color: rgb(241 185 56 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(241 185 56 / var(--tw-text-opacity));
}

.megamenu-apps-header--cp {
	--tw-border-opacity: 1;
	border-color: rgb(122 235 87 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(122 235 87 / var(--tw-text-opacity));
}

.megamenu-apps-header--cs {
	--tw-border-opacity: 1;
	border-color: rgb(50 221 240 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(50 221 240 / var(--tw-text-opacity));
}

.megamenu-apps-items {
	display: flex;
	max-width: 32rem;
	flex-direction: column;
	gap: 0px;
}

.megamenu-apps-item {
	margin-bottom: 0.75rem;
}

@media (min-width: 1024px) {
	.megamenu-apps-item {
		margin-bottom: 0px;
	}
}

.megamenu-apps-item-desc {
	font-family: "Gotham", sans-serif;
	padding-left: 1rem;
	padding-top: 0;
	margin: 0;
	padding-bottom: 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: -0.025em;
	--tw-text-opacity: 1;
	color: rgb(84 94 108 / var(--tw-text-opacity));
}

.megamenu-section-col {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.megamenu-section-col> :not([hidden])~ :not([hidden]) {
	--tw-divide-x-reverse: 0;
	border-right-width: calc(1px * var(--tw-divide-x-reverse));
	border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.megamenu-action-item1 {
	margin-right: 1rem;
}

@media (min-width: 1280px) {
	.megamenu-action-item1 {
		margin-right: 0.25rem;
	}
}

@media (min-width: 1440px) {
	.megamenu-action-item1 {
		margin-right: 1rem;
	}
}

.megamenu-action-item2 {
	display: none;
	height: 100%;
}

@media (min-width: 1280px) {
	.megamenu-action-item2 {
		display: block;
	}
}

.megamenu-action-item3 {
	display: none;
	height: 100%;
}

@media (min-width: 1024px) {
	.megamenu-action-item3 {
		display: block;
	}
}

.megamenu-action-item4 {
	margin-left: 0px;
}

.megamenu-action-item4.desktop {
	display: none;
}
@media (min-width: 720px) {
	.megamenu-action-item4.mobile {
		display: none;
	}
	.megamenu-action-item4.desktop {
		display: block;
	}
}

@media (min-width: 1024px) {
	.megamenu-action-item4 {
		margin-left: 1rem;
	}
}

.megamenu-action-item4.menu-item {
	height: auto;
}

.megamenu-action-search {
	display: flex;
	align-items: center;
}

.megamenu-action-links {
	display: flex;
	height: 100%;
	align-items: center;
	padding: 0.75rem;
	font-weight: 500;
	text-decoration: none;
	letter-spacing: -0.025em;
}

.megamenu-action-search-wrap {
	height: 1rem;
	width: 1rem;
}

@media (min-width: 640px) {
	.megamenu-action-search-wrap {
		height: 1.5rem;
		width: 1.5rem;
	}
}

.megamenu-action-trial-cta {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

@media (min-width: 768px) {
	.megamenu-action-trial-cta {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}
}

.megamenu-action-platform {
	margin-top: 1.25rem;
	text-align: center;
}

.megamenu-action-platform-cta {
	display: block;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(255 236 231 / var(--tw-bg-opacity));
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	--tw-text-opacity: 1;
	color: rgb(237 46 38 / var(--tw-text-opacity));
	text-decoration: none;
}

.megamenu-apps-container [data-cat="vulnerability"]:not(.activeCol) .megamenu-apps-item.q-link-was {
	display: none;
}

.megamenu-apps-container [data-cat="vulnerability"]:not(.activeCol) .megamenu-apps-item.q-link-api {
	display: none;
}

.megamenu-apps-container [data-cat="vulnerability"]:not(.activeCol) .megamenu-apps-item.q-link-web-malware {
	display: none;
}

.q-gl-header-wrapper {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1440px;
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (min-width: 640px) {
	.q-gl-header-wrapper {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
}

@media (min-width: 768px) {
	.q-gl-header-wrapper {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1024px) {
	.q-gl-header-wrapper {
		padding-left: clamp(30px, calc(1.875rem + ((1vw - 10.24px) * 11.7188)), 60px);
		padding-right: clamp(30px, calc(1.875rem + ((1vw - 10.24px) * 11.7188)), 60px);
	}
}

.q-gl-header-nav {
	position: relative;
	z-index: 10;
}

.q-cta-button {
	--tw-bg-opacity: 1;
	--tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08));
	align-items: center;
	background-color: rgb(237 46 38/var(--tw-bg-opacity));
	border-radius: 9999px;
	display: inline-flex;
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	height: 100%;
	justify-content: center;
	text-align: center;
	transition-duration: .15s;
	transition-property: all;
	transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	text-decoration: none;
}

.q-cta-button-txt {
	--tw-text-opacity: 1;
	color: rgb(243 245 248/var(--tw-text-opacity));
	font-size: .875rem;
	font-weight: 500;
	line-height: 1.25rem;
	line-height: 1;
	letter-spacing: -0.025em;
}

.q-cta-button-arrow {
	margin-left: .25rem;
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content
}

.q-megamenu-underline {
	position: absolute;
	bottom: 0px;
	height: 0.25rem;
	transform-origin: center;
	--tw-bg-opacity: 1;
	background-color: rgb(237 46 38 / var(--tw-bg-opacity));
}

.megamenu-action-item4.active + .megamenu-action-item4 + span.q-megamenu-underline.item-underline {
    display: none !important;
}

.q-mm-apps-grid {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 1.75rem;
}

.q-mm-col {
	display: flex;
	flex-direction: column;
}

.q-mm-col> :not([hidden])~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.activeCol {
	padding-top: 0px !important;
}

.q-mm-desc {
	display: none;
}

.activeCol .q-mm-desc {
	display: block;
}

/*! mobile menu */

.q-mobile-menu {
	transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
	transform: translateX(-100%);
	visibility: hidden;
	position: fixed;
	top: 0px;
	left: 0px;
	right: auto;
	z-index: 2010;
	box-sizing: border-box;
	display: block;
	height: 100%;
	width: 520px;
	max-width: 100%;
	overflow: hidden;
	--tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	will-change: transform;
}

@media (min-width: 360px) {
	.q-mobile-menu {
		height: 100vh;
	}
}

@media (min-width: 1024px) {
	.q-mobile-menu {
		display: none;
	}
}

.q-mobile-menu-bg {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.q-mobile-menu .q-menu-slider {
	transition: transform 300ms ease-in-out;
	transform: translateX(0);
	top: 0px;
	left: 0px;
	width: 100%;
	will-change: transform;
}

.q-mobile-menu ul {
	position: relative;
	margin: 0px;
	width: 520px;
	max-width: 100%;
	list-style-type: none;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.q-mobile-menu ul ul {
	position: absolute;
	left: 100%;
	top: 0px;
	display: none;
}

.q-mobile-menu.no-transition, .q-mobile-menu.no-transition * {
	transition-property: none !important;
}

.mobile-menu-on {
	overflow: hidden;
}

@media (min-width: 1024px) {
	.mobile-menu-on {
		overflow: auto;
	}
}

.q-mobile-menu-overlay {
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 2009;
	display: none;
	height: 100vh;
	width: 100vw;
	background-color: rgb(0 0 0 / 0.25);
}

.mobile-menu-on .q-mobile-menu-overlay {
	display: block;
}

@media (min-width: 1024px) {
	.mobile-menu-on .q-mobile-menu-overlay {
		display: none;
	}
}

.mobile_menu>li>a,
.mobile_menu>li>ul li>a {
	display: block;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 16px;
	line-height: 1.75;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(84 94 108 / var(--tw-text-opacity));
	text-decoration: none;
	font-family: "Gotham", sans-serif;
}

.q-mobile-menu-control {
	margin: 0px;
	height: 1.25rem;
	width: 1.25rem;
	cursor: pointer;
	background-color: transparent;
	background-image: none;
	padding: 0px;
}

@media (min-width: 768px) {
	.q-mobile-menu-control {
		height: 1.5rem;
		width: 1.5rem;
	}
}

.q-mobilemenu-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.q-mobilemenu-close-btn {
	margin: 0px;
	height: 1.5rem;
	width: 1.5rem;
	background-color: transparent;
	padding: 0px;
}

.q-mobilemenu-header {
	margin-bottom: 0.75rem;
	border-bottom-width: 1px;
	--tw-border-opacity: 1;
	border-color: rgb(224 228 234 / var(--tw-border-opacity));
	padding-bottom: 0.75rem;
}

.q-mobilemenu-header-txt {
	font-family: "Gotham", sans-serif;
	display: block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 16px;
	line-height: 1.75;
	--tw-text-opacity: 1;
	color: rgb(33 37 44 / var(--tw-text-opacity));
}

.q-mobilemenu-search {
	margin-top: 2.25rem;
	margin-bottom: 2rem;
}

.q-site-search-container {
	display: flex;
	width: 100%;
	align-items: center;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(243 245 248 / var(--tw-bg-opacity));
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.q-mobilemenu-search-icon {
	margin-right: 0.5rem;
	height: 1.25rem;
	width: 1.25rem;
}

.q-mobilemenu-search-field {
	flex: 1 1 0%;
	border-width: 0px;
	background-color: transparent;
	font-size: 0.875rem;
	line-height: 1.5rem;
	--tw-text-opacity: 1;
	color: rgb(51 59 70 / var(--tw-text-opacity));
	outline: 2px solid transparent;
	outline-offset: 2px;
	font-family: "Gotham", sans-serif;
}

.q-mobilemenu-search-field::-ms-input-placeholder {
	--tw-text-opacity: 1;
	color: rgb(132 141 156 / var(--tw-text-opacity));
}

.q-mobilemenu-search-field::placeholder {
	--tw-text-opacity: 1;
	color: rgb(132 141 156 / var(--tw-text-opacity));
}

.q-mobilemenu-trial {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(237 46 38 / var(--tw-bg-opacity));
	padding: 1rem;
	text-align: center;
	--tw-text-opacity: 1 !important;
	color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.q-mobilemenu-trial-txt {
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	line-height: 1.625;
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.q-mobilemenu-separator {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	padding-bottom: 0.75rem;
}

.q-mobilemenu-mt-14 {
	margin-top: 3.5rem;
}

.q-mobilemenu-back {
	margin-bottom: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.q-mobilemenu-back-wrap {
	display: flex;
	align-items: center;
}

.q-mobilemenu-back-btn {
	height: 0.5rem;
	display: flex;
	padding: 0;
	align-items: center;
	background-color: transparent;
}

.q-mobilemenu-back-icon {
	margin-right: 0.25rem;
	height: 1.5rem;
	width: 1.5rem;
}

.q-mobilemenu-back-txt {
	font-family: "Gotham", sans-serif;
	font-size: 0.875rem;
	line-height: 1.25rem;
	--tw-text-opacity: 1;
	color: rgb(132 141 156 / var(--tw-text-opacity));
}

.q-mobilemenu-back-sep {
	margin-left: 1rem;
	margin-right: 1rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(33 37 44 / var(--tw-text-opacity));
}

.q-mobilemenu-back-base {
	font-family: "Gotham", sans-serif;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(33 37 44 / var(--tw-text-opacity));
}

.q-mobilemenu-back-close {
	height: 1.5rem;
	width: 1.5rem;
	background-color: transparent;
	padding: 0;
}

.q-mobilemenu-platform-overview {
	font-family: "Gotham", sans-serif;
	margin-top: 0;
	margin-bottom: 2rem;
	max-width: 20rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.025em;
	--tw-text-opacity: 1;
	color: rgb(84 94 108 / var(--tw-text-opacity))
}

.q-mobilemenu-mb-4 {
	margin-bottom: 1rem;
}

.q-mobilemenu-app-cat {
	font-family: "Gotham", sans-serif;
	display: block;
	border-radius: 0.25rem;
	border-left-width: 6px;
	padding-left: 0.75rem;
	font-size: 16px;
	line-height: 1.75;
	font-weight: 500;
	text-transform: uppercase;
}

.q-mobilemenu-app-cat--av {
	--tw-border-opacity: 1;
	border-color: rgb(104 109 252 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(104 109 252 / var(--tw-text-opacity));
}

.q-mobilemenu-mt-7 {
	margin-top: 1.75rem;
}

.q-mobilemenu-app-cat--vm {
	--tw-border-opacity: 1;
	border-color: rgb(240 65 65 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(240 65 65 / var(--tw-text-opacity));
}

.q-mobilemenu-app-cat--rr {
	--tw-border-opacity: 1;
	border-color: rgb(255 123 27 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(255 123 27 / var(--tw-text-opacity));
}

.q-mobilemenu-app-cat--td {
	--tw-border-opacity: 1;
	border-color: rgb(241 185 56 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(241 185 56 / var(--tw-text-opacity));
}

.q-mobilemenu-app-cat--cp {
	--tw-border-opacity: 1;
	border-color: rgb(122 235 87 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(122 235 87 / var(--tw-text-opacity));
}

.q-mobilemenu-app-cat--cs {
	--tw-border-opacity: 1;
	border-color: rgb(50 221 240 / var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgb(50 221 240 / var(--tw-text-opacity));
}

.q-mobilemenu-mt-5 {
	margin-top: 1.25rem;
}

.q-mobilemenu-platform-btn {
	display: block;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(255 236 231 / var(--tw-bg-opacity));
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	--tw-text-opacity: 1 !important;
	color: rgb(237 46 38 / var(--tw-text-opacity)) !important;
}

/*! end of header */

/*! Footer styles */

.qfooter-wrapper {
	position: relative;
	margin-top: -29px;
}

@media (min-width: 768px) {
	.qfooter-wrapper {
		margin-top: -41px;
	}
}

.qfooter-top-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
}

.qfooter-top-container {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -1px;
	width: 280px;
	max-width: 100%;
}

@media (min-width: 768px) {
	.qfooter-top-container {
		width: 402px;
	}
}

.qfooter-svg-fill {
	display: block;
	fill: #1D2737;
}

.qfooter-main-wrapper {
	width: 100%;
	--tw-bg-opacity: 1;
	background-color: rgb(29 39 55 / var(--tw-bg-opacity));
}

.q-wrapper-footer {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1440px;
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (min-width: 640px) {
	.q-wrapper-footer {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
}

@media (min-width: 768px) {
	.q-wrapper-footer {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1024px) {
	.q-wrapper-footer {
		padding-left: clamp(30px, calc(1.875rem + ((1vw - 10.24px) * 11.7188)), 60px);
		padding-right: clamp(30px, calc(1.875rem + ((1vw - 10.24px) * 11.7188)), 60px);
	}
}

.q-container-footer {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.q-container-footer> :not([hidden])~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
	--tw-divide-opacity: 1;
	border-color: rgb(84 94 108 / var(--tw-divide-opacity));
}

.qfooter-shield {
	padding-bottom: 1.5rem;
}

@media (min-width: 1024px) {
	.qfooter-shield {
		padding-top: 1rem;
		padding-bottom: 2.75rem;
	}
}

.qfooter-shield-img {
	margin-left: auto;
	margin-right: auto;
	width: 2.25rem;
}

@media (min-width: 768px) {
	.qfooter-shield-img {
		width: 75px;
	}
}

.qfooter-content-wrap {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (min-width: 1024px) {
	.qfooter-content-wrap {
		padding-top: 3rem;
		padding-bottom: 3.5rem;
	}
}

.qfooter-content-container {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 30px;
}

@media (min-width: 640px) {
	.qfooter-content-container {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (min-width: 1024px) {
	.qfooter-content-container {
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
}

.qfooter-col-1 {
	grid-column: span 2 / span 2;
}

@media (min-width: 640px) {
	.qfooter-col-1 {
		grid-column: span 3 / span 3;
	}
}

@media (min-width: 1024px) {
	.qfooter-col-1 {
		grid-column: span 6 / span 6;
	}
}

@media (min-width: 1440px) {
	.qfooter-col-1 {
		grid-column: span 5 / span 5;
	}
}

.qfooter-tagline {
	letter-spacing: -0.4px;
	font-family: "Gotham", sans-serif;
	margin-bottom: 0.5rem;
	margin-top: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 400;
	--tw-text-opacity: 1;
	color: rgb(243 245 248 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
	.qfooter-tagline {
		font-size: 22px;
	}
}

@media (min-width: 1024px) {
	.qfooter-tagline {
		max-width: 24rem;
		line-height: 2.25rem;
	}
}

.qfooter-intro-txt {
	letter-spacing: -0.4px;
	font-family: "Gotham", sans-serif;
	padding-bottom: 1.5rem;
	font-size: 16px;
	line-height: 1.75;
	font-weight: 400;
	line-height: 2rem;
	--tw-text-opacity: 1;
	color: rgb(224 228 234 / var(--tw-text-opacity));
	margin: 0;
}

@media (min-width: 1024px) {
	.qfooter-intro-txt {
		padding-bottom: 2.25rem;
	}
}

.qfooter-search-box {
	padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
	.qfooter-search-box {
		padding-bottom: 1.75rem;
	}
}

@media (min-width: 1024px) {
	.qfooter-search-box {
		padding-bottom: 2.25rem;
	}
}

.qfooter-social-items {
	margin-bottom: 0.5rem;
	border-bottom-width: 1px;
	--tw-border-opacity: 1;
	border-color: rgb(84 94 108 / var(--tw-border-opacity));
	padding-bottom: 1.5rem;
}

@media (min-width: 1024px) {
	.qfooter-social-items {
		margin-bottom: 0px;
		border-bottom-width: 0px;
		padding-bottom: 0px;
	}
}

.qfooter-search-content {
	border-radius: 9999px;
	background-color: rgb(255 255 255 / 0.2);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 1.75rem;
}

@media (min-width: 1280px) {
	.qfooter-search-content {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-right: 1rem;
	}
}

.qfooter-search-form {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.25rem;
}

.qfooter-search-field-container {
	flex: 1 1 0%;
}

.qfooter-search-field {
	font-family: "Gotham", sans-serif;
	font-size: 100%;
	width: 100%;
	background-color: transparent;
	--tw-text-opacity: 1;
	color: rgb(243 245 248 / var(--tw-text-opacity));
}

.qfooter-search-field::placeholder {
	color: rgb(243 245 248 / 0.65)
}

.qfooter-search-field:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}

.qfooter-search-btn {
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(237 46 38 / var(--tw-bg-opacity));
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.qfooter-search-btn>svg {
	display: block;
}

.qfooter-social-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.125rem;
	padding: 0;
	margin: 0;
	list-style: none;
}

.qfooter-social-link {
	display: flex;
	height: 2.75rem;
	width: 2.75rem;
	align-items: center;
	justify-content: center;
}

.qfooter-col-2 {
	grid-column: span 1 / span 1;
}

@media (min-width: 1024px) {
	.qfooter-col-2 {
		grid-column: span 2 / span 2;
	}
}

@media (min-width: 1440px) {
	.qfooter-col-2 {
		grid-column-start: 7;
	}
}

.qfooter-links-list {
	margin: 0px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	padding: 0px;
	font-size: 0.875rem;
	line-height: 1.25rem;
	list-style: none;
}

@media (min-width: 1024px) {
	.qfooter-links-list {
		gap: 30px;
	}
}

.qfooter-links-txt {
	font-family: "Gotham", sans-serif;
	font-weight: 500;
	letter-spacing: -0.4px;
	--tw-text-opacity: 1;
	color: rgb(243 245 248 / var(--tw-text-opacity));
}

.qfooter-links-a {
	font-family: "Gotham", sans-serif;
	text-decoration: none;
	letter-spacing: -0.4px;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(171 180 197 / var(--tw-text-opacity));
	transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
	transition-timing-function: linear;
}

.qfooter-links-a:hover {
	--tw-text-opacity: 1;
	color: rgb(191 198 210 / var(--tw-text-opacity));
}

.qfooter-col-3 {
	grid-column: span 1 / span 1;
}

@media (min-width: 1024px) {
	.qfooter-col-3 {
		grid-column: span 2 / span 2;
	}
}

.qfooter-col-4 {
	grid-column: span 2 / span 2;
}

@media (min-width: 640px) {
	.qfooter-col-4 {
		grid-column: span 1 / span 1;
	}
}

@media (min-width: 768px) {
	.qfooter-col-4 {
		grid-column: span 1 / span 1;
	}
}

@media (min-width: 1024px) {
	.qfooter-col-4 {
		grid-column: span 2 / span 2;
	}
}

.qfooter-copyright {
	padding-top: 1.5rem;
	padding-bottom: 2.75rem;
	text-align: center;
	--tw-text-opacity: 1;
	color: rgb(132 141 156 / var(--tw-text-opacity));
}

.qfooter-copyright-content {
	padding: 0px;
	margin: 0 auto;
	max-width: fit-content;
	font-family: "Gotham", sans-serif;
}

.qfooter-copyright-content>a {
	text-decoration: none;
}

/*! footer styles end */

/*! topbar styles starts */

.q-topbar-wrapper {
	z-index: 0;
	display: none;
	font-family: "Gotham", sans-serif;
	letter-spacing: -0.4px;
}

@media (min-width: 1024px) {
	.q-topbar-wrapper {
		display: block;
	}
}

.q-topbar-container {
	--tw-bg-opacity: 1;
	background-color: rgb(10 72 130 / var(--tw-bg-opacity));
	padding-top: 0.5rem;
}

.q-topbar-content {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.q-topbar-py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.q-topbar-w-full {
	width: 100%;
}

.q-topbar-opacity-0 {
	opacity: 0;
}

.q-topbar-h-8 {
	height: 2rem;
}

.q-topbar-slide {
	display: flex;
	flex-direction: row;
}

.q-topbar-slide-content {
	margin-right: 0.75rem;
	display: flex;
	flex: 1 1 0%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	line-height: 1.75;
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.q-topbar-slide-cta {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.q-topbar-slide-cta-txt {
	font-size: 0.875rem;
	line-height: 1.25rem;
	--tw-text-opacity: 1;
	color: rgb(255 236 231 / var(--tw-text-opacity));
}

.q-topbar-slide-cta-arrow {
	margin-left: 0.5rem;
	height: 0.75rem;
	width: 0.75rem;
}

.q-topbar-slide-cta-arrow > svg {
	display: block;
}

.q-topbar-font-medium {
	font-weight: 400;
}

.q-topbar-ml-1 {
	margin-left: 0.25rem;
}

.q-topbar-slide-txt {
	font-weight: 400;
	--tw-text-opacity: 1;
	color: rgb(209 213 219 / var(--tw-text-opacity));
}

/*! topbar styles ends */

*, ::before, ::after {
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: #e5e7eb;
}

.q-megamenu-new-lbl {
	color: #ed2e26;
	font-weight: 600;
	vertical-align: super;
	margin-left: 3px;
	top: 0 !important;
	font-size: 75%;
}